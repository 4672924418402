import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
    config: {
        connectionString:
            "InstrumentationKey=cf432019-06b4-42cb-a765-7e349dd1dcf9;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/;ApplicationId=a1ffac9e-b1ce-4510-98b1-07e8ffe2b38f"
    }
});
appInsights.loadAppInsights();

export { appInsights };
