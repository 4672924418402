import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "Summarize CD-64 zone in White Rock (Comprehensive Development 64).",
    "Can I walk my dog on the promenade?",
    "Use a table to list the parameters for RT-2 zone.",
    "List the process for the major development.",
    "What safety measures should be taken when storing gas containers in a garage?",
    "Where can I check the recycling calendar?",
    "Who should I contact if a tree obstructing driver sightlines at an intersection?",
    "Do I need a permit if I want to create a parking area on the boulevard in front of my house?",
    "How to know where the sanitary/storm connection of my house is located?",
    "Where to pay the water utility bill?"
];

const GPT4V_EXAMPLES: string[] = [
    "Compare the impact of interest rates and GDP in financial markets.",
    "What is the expected trend for the S&P 500 index over the next five years? Compare it to the past S&P 500 performance",
    "Can you identify any correlation between oil prices and stock market trends?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
